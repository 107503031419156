import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useAtom } from "jotai";

import {
    actualGameLinkAtom,
    isFullScreen,
    authAtom,
    idAtom,
    isTelegramAtom,
    pgSoftAtom,
} from "../App";
import { useVisitorData } from "@fingerprintjs/fingerprintjs-pro-react";
export default function Game() {
    const [userId, setId] = useAtom(idAtom);
    const [fullScreen, setFullScreen] = useAtom(isFullScreen);
    const [pgSoftIds, setPgSoftIds] = useAtom(pgSoftAtom);
    const [auth, setAuth] = useAtom(authAtom);
    const [actualGameLink, setActualGameLink] = useAtom(actualGameLinkAtom);
    const frame = useRef();
    const [t, i18n] = useTranslation();
    const navigate = useNavigate();
    const { id } = useParams();
    const [isTelegram] = useAtom(isTelegramAtom);
    const [gameRendered, setGameRendered] = useState(false);
    const { isLoading, error, data, getData } = useVisitorData(
        { extendedResult: true },
        { immediate: true },
    );

    useEffect(() => {
        document.body.classList.add("game-open");
        return () => {
            document.body.classList.remove("game-open");
        };
    }, []);

    const [metricsSent, setMetricsSent] = useState(false);
    useEffect(() => {
        if (metricsSent == true) return;
        if (data?.visitorId != null) {
            setMetricsSent(true);
            axios
                .post(
                    process.env.REACT_APP_API + "/user-metrics",
                    {
                        type: "play",
                        visitor_id: data.visitorId,
                    },
                    {
                        headers: {
                            Authorization:
                                "Bearer " + localStorage.getItem("accessToken"),
                        },
                    },
                )
                .then((response) => {})
                .catch((err) => {});
        }
    }, [data]);
    useEffect(() => {
        axios
            .post(
                process.env.REACT_APP_API + "/games/gameLink",
                {
                    userId: userId,
                    gameId: id,
                },
                {
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("accessToken"),
                    },
                },
            )
            .then((response) => {
                if (response.data.status === "success") {
                    setActualGameLink(response.data.content.game.url);

                    setGameRendered(true);

                    if (isMobile) {
                        document
                            .querySelector("body")
                            .classList.add("disable-scroll");
                        document
                            .querySelector("html")
                            .classList.add("disable-scroll");

                        setTimeout(() => {
                            document.documentElement.style.setProperty(
                                "--window-inner-height",
                                `${window.innerHeight}px`,
                            );
                        }, 100);
                        if (document.documentElement.requestFullScreen) {
                            document.documentElement.requestFullScreen();
                        } else if (
                            document.documentElement.mozRequestFullScreen
                        ) {
                            document.documentElement.mozRequestFullScreen();
                        } else if (
                            document.documentElement.webkitRequestFullScreen
                        ) {
                            document.documentElement.webkitRequestFullScreen();
                        }
                    }

                    return;
                }
                navigate("/");
                /*   toast.error(response.data.status);
                   toast.error(JSON.stringify(response.data));
                   toast.error(JSON.stringify({
                    
                    userId:userId,
                    gameId:id
             
                   }))*/
            });
    }, []);
    return (
        <>
            {actualGameLink ? (
                <div className="game-frame__wrapper">
                    {pgSoftIds.includes(Number(id)) && !isTelegram && (
                        <>
                            <a
                                className="back--button"
                                onClick={() => {
                                    navigate("/");
                                }}
                            >
                                <svg
                                    width="32px"
                                    height="32px"
                                    viewBox="0 0 512 512"
                                    version="1.1"
                                    xmlSpace="preserve"
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                >
                                    <style
                                        type="text/css"
                                        dangerouslySetInnerHTML={{
                                            __html: "\n\t.st0{fill:#fff;}\n",
                                        }}
                                    />
                                    <g id="Layer_1" />
                                    <g id="Layer_2">
                                        <g>
                                            <path
                                                className="st0"
                                                d="M217,129.88c-6.25-6.25-16.38-6.25-22.63,0L79.61,244.64c-0.39,0.39-0.76,0.8-1.11,1.23    c-0.11,0.13-0.2,0.27-0.31,0.41c-0.21,0.28-0.42,0.55-0.62,0.84c-0.14,0.21-0.26,0.43-0.39,0.64c-0.14,0.23-0.28,0.46-0.41,0.7    c-0.13,0.24-0.24,0.48-0.35,0.73c-0.11,0.23-0.22,0.45-0.32,0.68c-0.11,0.26-0.19,0.52-0.28,0.78c-0.08,0.23-0.17,0.46-0.24,0.69    c-0.09,0.29-0.15,0.58-0.22,0.86c-0.05,0.22-0.11,0.43-0.16,0.65c-0.08,0.38-0.13,0.76-0.17,1.14c-0.02,0.14-0.04,0.27-0.06,0.41    c-0.11,1.07-0.11,2.15,0,3.22c0.01,0.06,0.02,0.12,0.03,0.18c0.05,0.46,0.12,0.92,0.21,1.37c0.03,0.13,0.07,0.26,0.1,0.39    c0.09,0.38,0.18,0.76,0.29,1.13c0.04,0.13,0.09,0.26,0.14,0.4c0.12,0.36,0.25,0.73,0.4,1.09c0.05,0.11,0.1,0.21,0.15,0.32    c0.17,0.37,0.34,0.74,0.53,1.1c0.04,0.07,0.09,0.14,0.13,0.21c0.21,0.38,0.44,0.76,0.68,1.13c0.02,0.03,0.04,0.06,0.06,0.09    c0.55,0.81,1.18,1.58,1.89,2.29l114.81,114.81c3.12,3.12,7.22,4.69,11.31,4.69s8.19-1.56,11.31-4.69c6.25-6.25,6.25-16.38,0-22.63    l-87.5-87.5h291.62c8.84,0,16-7.16,16-16s-7.16-16-16-16H129.51L217,152.5C223.25,146.26,223.25,136.13,217,129.88z"
                                            />
                                        </g>
                                    </g>
                                </svg>
                            </a>
                        </>
                    )}
                    {gameRendered && (
                        <>
                            <iframe
                                style={
                                    fullScreen
                                        ? {
                                              marginTop: "5svh",
                                              height: "95svh",
                                          }
                                        : {}
                                }
                                ref={frame}
                                className="game-frame"
                                src={actualGameLink}
                            />
                        </>
                    )}
                </div>
            ) : (
                <></>
            )}
        </>
    );
}