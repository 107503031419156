import {
    Routes,
    Route,
    useNavigate,
    useLocation,
} from "react-router-dom";
import Main from "./pages/Main";
import Profile from "./pages/Profile/Profile";
import "./styles/globals.css";
import Header from "./components/Header";
import Verification from "./pages/Profile/Verification";
import Footer from "./components/Footer";
import ProtectedRoute from "./components/ProtectedRoute.tsx";
import { useTranslation } from "react-i18next";
import { useCallback, useState } from "react";
import { useEffect } from "react";
import { toast } from "react-toastify";
import About from "./pages/About";
import NotFound from "./pages/NotFound";
import { useLayoutEffect } from "react";
import Safety from "./pages/Profile/Safety";
import Wallets from "./pages/Profile/Wallet/Wallets";
import Deposit from "./pages/Profile/Wallet/Deposit";
import Withdraw from "./pages/Profile/Wallet/Withdraw";
import WalletHistory from "./pages/Profile/Wallet/WalletHistory";
import FollowTelegramAlert from "./components/followTelegramAlert";
import Notifications from "./pages/Profile/Notifications";
import Reload from "./pages/Profile/Reload";
import SelfLimitation from "./pages/Profile/SelfLimitation";
import History from "./pages/Profile/History";
import Bonuses from "./pages/Profile/Bonuses";
import BonusesPage from "./pages/Bonuses";
import Vip from "./pages/Profile/Vip";
import Faq from "./pages/Faq";
import VipClub from "./pages/VipClub";
import Payments from "./pages/Payments";
import Terms from "./pages/Terms";
import ResponsibleGambling from "./pages/Responsible-gambling";
import Policy from "./pages/Policy";
import MobileMenu from "./components/MobileMenu";
import { ToastContainer } from "react-toastify";
import Game from "./components/Game";
import axios from "axios";
import { isMobile } from "react-device-detect";
import { atom, useAtom } from "jotai";
import { io } from "socket.io-client";
import Referral from "./pages/Profile/Referral.jsx";
import AuthTg from "./components/AuthTg.jsx";
import Wheel from "./pages/Wheel.jsx";
import GamesPage from "./pages/GamesPage.jsx";
import { useVisitorData } from "@fingerprintjs/fingerprintjs-pro-react";
import FingerprintJS from "@fingerprintjs/fingerprintjs";
import Support from "./components/support.jsx";
import ReactPixel from 'react-facebook-pixel';

export const usernameAtom = atom("");
export const authAtom = atom(false);
export const telegramUsernameAtom = atom("");
export const pgSoftAtom = atom([]);
export const phoneAtom = atom("");
export const idAtom = atom("");
export const gamesAtom = atom([]);
export const gamesOverallAtom = atom([]);
export const isTelegramAtom = atom(false);
export const needFinishReg = atom(false);
export const gamesCategoriesAtom = atom({});
export const currentGamesCategoriesAtom = atom(false);
export const wheelSpinnedAtom = atom(false);

export const constructGameObject = (x, i) => ({
  name: x[i].name,
  img: x[i].img,
  label: x[i].label,
  id: x[i].id,
  isLiked: x[i].isLiked,
});

export const avatarAtom = atom(
    "/images/vip/ri_13_" +
        ("00".substring(0, 2 - (1 + "").length) + 1) +
        ".png",
);
export const notificationsAtom = atom([
    {
      date: "---",
      message: "Загрузка...",
      isViewed: false,
    },
  ]);
  export const providerListAtom = atom([]);
  export const payDataAtom = atom({
    open: false,
    isSbp: false,
  });
  export const payDataTimeAtom = atom(0);
  export const logInOpenAtom = atom(false);
  export const actualGameLinkAtom = atom("");
  export const authLoadedAtom = atom(false);
  export const balanceAtom = atom({
    RUB: 0,
    USD: 0,
    EUR: 0,
  });
  export const depositOpenAtom = atom(false);
  export const socketAtom = atom("");
  export const isFullScreen = atom(false);
  const fpPromise = FingerprintJS.load();
  function App() {
    const [, setTelegram] = useAtom(isTelegramAtom);
    const [, setNotifications] = useAtom(notificationsAtom);
    const location = useLocation();
    const [, setNeedFill] = useAtom(needFinishReg);
    const [, setSocket] = useAtom(socketAtom);
    const [payData, setPayData] = useAtom(payDataAtom);
    const navigate = useNavigate();
    const [auth, setAuth] = useAtom(authAtom);
    const [authLoaded, setAuthLoaded] = useAtom(authLoadedAtom);
    const [actualGameLink, setActualGameLink] = useAtom(actualGameLinkAtom);
    const [loading, setLoading] = useState(true);
    const [, setId] = useAtom(idAtom);
    const [, setGames] = useAtom(gamesAtom);
    const [, setGamesOverall] = useAtom(gamesOverallAtom);
    const [balance, setBalance] = useAtom(balanceAtom);
    const [, setProviderList] = useAtom(providerListAtom);
    const [, setPgSoftIds] = useAtom(pgSoftAtom);
  
    const [, setFullScreen] = useAtom(isFullScreen);

    useEffect(() => {
      ReactPixel.init('876081364709492'); 
      ReactPixel.pageView(); 
    }, []);

    const loadSocket = () => {
      var d = io.connect(process.env.REACT_APP_SOCKET, {
        auth: {
          token: localStorage.getItem("accessToken"),
        },
        secure: true,
      });
  
      d.on("payment.crypto.success", (data) => {
        toast.success("Платеж через криптовалюту зачислен!");
      });
      d.on("payment.crypto.pending", (data) => {
        toast.info(
          "Платеж через криптовалюту создан и находится в ожидании оплаты!",
        );
      });
      d.on("payment.bank.success", (data) => {
        if (!payData.id == data.transaction_id) {
          return;
        }
        setPayData((payData) => ({
          ...payData,
          open: true,
          loading: "success",
        }));
        setTimeout(() => {
          setPayData((payData) => ({ open: false }));
        }, 4000);
      });
      d.on("payment.bank.cancelled", (data) => {
        if (!payData.id == data.transaction_id) {
          return;
        }
  
        setPayData((payData) => ({
          ...payData,
          open: true,
          loading: "cancel",
        }));
        setTimeout(() => {
          setPayData({ open: false });
        }, 4000);
      });
      d.on("balanceUpdated", (data) => {
        setBalance({ ...balance, RUB: data.balance });
      });
      setSocket(d);
    };
  
    useEffect(() => {
      let resizeObserver;
      try {
          window.Telegram.WebApp.requestFullscreen();
          setFullScreen(true);

          resizeObserver = new ResizeObserver(() => {
              const isKeyboardOpen =
                  window.innerHeight < screen.height * 0.75;
              if (isKeyboardOpen) {
                  document
                      .querySelector(".MobileMenu")
                      ?.classList.add("hidden");
              } else {
                  document
                      .querySelector(".MobileMenu")
                      ?.classList.remove("hidden");
              }
          });
      } catch (e) {
          console.log(e);
      }

      sendUserMetricRequest();

      if (resizeObserver) {
          resizeObserver.observe(document.body);
          return () => resizeObserver.disconnect();
      }
    }, []);
  
    async function sendUserMetricRequest() {
      try {
        const visitorId = await getVisitorId();
  
        await axios.post(
          process.env.REACT_APP_API + "/user-metrics",
          {
            type: "visit",
            visitor_id: visitorId,
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("accessToken"),
            },
          },
        );
      } catch (err) {
        console.error("Error sending user-metric request!");
        console.log(err.message);
      }
    }
  
    async function getVisitorId() {
      const fp = await fpPromise;
      const result = await fp.get();
      return result.visitorId;
    }
  
    useEffect(() => {
        setTimeout(function () {
            window.scrollTo(0, 1);
        }, 0);

        const fetchUserData = async () => {
            const token = localStorage.getItem("accessToken");

            if (!token) {
                setAuth(false);
                setAuthLoaded(true);
                return;
            }

            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_API}/user/me`,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    },
                );

                const user = response.data.user;
                setAuth(true);
                setNeedFill(!user.isProfileFilled);
                setNotifications(response.data.notifications[0] || []);
                setId(user.id);
                setBalance((prev) => ({ ...prev, RUB: user.balance }));
                loadSocket();


                let bonus = (await axios
                .get(process.env.REACT_APP_API + "/games/bonus-info")).data.isWheelActive;

                if ((localStorage.getItem("referralId") || localStorage.getItem("referralInvitationId")) && !response.data.user.wasBonusActivated && bonus) {
                  navigate("/spin");
                }
            } catch (error) {
                console.error("Failed to fetch user data:", error);
                setAuth(false);
            } finally {
                setAuthLoaded(true);
            }
        };

        fetchUserData();

        axios
            .get(process.env.REACT_APP_API + "/games/providers")
            .then((response) => {
                setProviderList(response.data);
            });

        axios
            .get(process.env.REACT_APP_API + "/games/main", {
                headers: {
                    Authorization:
                        "Bearer " + localStorage.getItem("accessToken"),
                },
            })
            .then((response) => {
                var gamesRef = [];
                var pgSoftList = [];
                for (var i = 0; i < response.data.length; i++) {
                    if (
                        (response.data[i].device == "0" && !isMobile) ||
                        (response.data[i].device == "1" && isMobile) ||
                        response.data[i].device == "2"
                    ) {
                        if (response.data[i].label == "pgsoft")
                            pgSoftList.push(Number(response.data[i].id));
                        gamesRef.push(constructGameObject(response.data, i));

                    }
                }
                setPgSoftIds(pgSoftList);

                setGamesOverall(gamesRef);
                setGames(gamesRef);
            });
        window.onmessage = function (event) {
            if (
                event.data == "closeGame" ||
                event.data == "close" ||
                event.data == "notifyCloseContainer" ||
                (event.data.indexOf &&
                    event.data.indexOf("GAME_MODE:LOBBY") >= 0)
            ) {
                navigate("/");
            } else if (event.data == "closeWheel") {
                navigate("/");
            }
            if (event.data.closeGame !== undefined) {
                navigate("/");
            }
        };

       
    }, []);
  
    useLayoutEffect(() => {
      window.scrollTo(0, 0);
    });
    const { i18n } = useTranslation();
    const handlePopState = useCallback(() => {
      if (document.body.classList.contains("game-open"))
        window.location.href = "/";
    }, []);


    useEffect(() => {
      window.addEventListener("popstate", handlePopState);
      return () => {
        window.removeEventListener("popstate", handlePopState);
      };
    }, [handlePopState]);
  
    const { data } = useVisitorData(
      { extendedResult: true },
      { immediate: true },
    );
  
    console.log(data);
    useEffect(() => {
      if (data?.visitorId != null) {
        axios
          .post(process.env.REACT_APP_API + "/user-metrics", {
            type: "visit",
            visitor_id: data.visitorId,
          })
          .catch(() => {});
      }
    }, [data]);
  
    useEffect(async () => {
        setTelegram(window.Telegram.WebApp.initDataUnsafe.user != null);
        console.log(window.Telegram.WebApp.initDataUnsafe.user);
        document.body.addEventListener("click", function (e) {
            var newCu = e.target;
            while (newCu.parentElement) {
                newCu = newCu.parentElement;
                if (newCu == document.body) break;
                if (newCu.classList.contains("selector")) return;
            }
            document.querySelectorAll(".selector").forEach(element => {
              element.classList.remove("open");
            });
        });
        window.addEventListener("popstate", function (e) {
            if (actualGameLink) {
                e.preventDefault();
                setActualGameLink(false);
            }
        });
        document.onkeyup = function (e) {
            if (e.key === "Escape") setActualGameLink(false);
        };

        var prm = new URLSearchParams(document.location.search);

        const ur = prm.get("user_referral_id");
        const ri = prm.get("referral_invitation_id");

        if (ur) {
            localStorage.setItem("referralId", ur);
        } else if (ri) {
            localStorage.setItem("referralInvitationId", ri);
        }

        if ((ur || ri) && !auth && !localStorage.getItem("accessToken") && !window.Telegram.WebApp.initDataUnsafe.user) {
          navigate("/spin");
        }
        setLoading(false);


        if (localStorage.getItem("i18nextLng") != null)
            i18n.changeLanguage(localStorage.getItem("i18nextLng"));
    }, []);
    

  
    useEffect(() => {
      ReactPixel.pageView();
    }, [location]);
  
  
    return (
      <>
        <ToastContainer theme="dark" />
        <div className="container-main">
          {location.pathname.includes("/spin") ? (
            <Wheel />
          ) : !loading ? (
            authLoaded && (
              <>
                <Support />

                <AuthTg />

                {!location.pathname.includes("/game/") && <Header />}
  
                <Routes>
                  <Route path="*" element={<NotFound />}></Route>
                  <Route path="/" element={<Main />}></Route>
                  <Route path="/games" element={<GamesPage />}></Route>
  
                  <Route path="/about" element={<About />}></Route>
                  <Route
                    path="/profile/self-limitation"
                    element={
                      <ProtectedRoute>
                        {" "}
                        <SelfLimitation />{" "}
                      </ProtectedRoute>
                    }
                  ></Route>
                  <Route
                    path="/profile/reload"
                    element={
                      <ProtectedRoute>
                        {" "}
                        <Reload />{" "}
                      </ProtectedRoute>
                    }
                  ></Route>
                  <Route
                    path="/profile/referral"
                    element={
                      <ProtectedRoute>
                        {" "}
                        <Referral />{" "}
                      </ProtectedRoute>
                    }
                  ></Route>
                  <Route
                    path="/profile/bonuses"
                    element={
                      <ProtectedRoute>
                        {" "}
                        <Bonuses />{" "}
                      </ProtectedRoute>
                    }
                  ></Route>
                  <Route
                    path="/profile/vip"
                    element={
                      <ProtectedRoute>
                        {" "}
                        <Vip />{" "}
                      </ProtectedRoute>
                    }
                  ></Route>
                  <Route path="/faq" element={<Faq />}></Route>
                  <Route path="/bonuses" element={<BonusesPage />}></Route>
                  <Route path="/payments" element={<Payments />}></Route>
                  <Route path="/terms" element={<Terms />}></Route>
                  <Route path="/spin" element={<Wheel />}></Route>
                  <Route path="/game/:id" element={<Game />}></Route>
                  <Route
                    path="/responsible-gambling"
                    element={<ResponsibleGambling />}
                  ></Route>
                  <Route path="/policy" element={<Policy />}></Route>
                  <Route path="/vip-club" element={<VipClub />}></Route>
                  <Route
                    path="/profile/history"
                    element={
                      <ProtectedRoute>
                        {" "}
                        <History />{" "}
                      </ProtectedRoute>
                    }
                  ></Route>
                  <Route
                    path="/profile/notifications"
                    element={
                      <ProtectedRoute>
                        <Notifications />
                      </ProtectedRoute>
                    }
                  ></Route>
                  <Route
                    path="/profile/wallet/wallets"
                    element={
                      <ProtectedRoute>
                        <Wallets />
                      </ProtectedRoute>
                    }
                  ></Route>
                  <Route
                    path="/profile/wallet/history"
                    element={
                      <ProtectedRoute>
                        <WalletHistory />{" "}
                      </ProtectedRoute>
                    }
                  ></Route>
                  <Route
                    path="/profile/wallet/withdraw"
                    element={
                      <ProtectedRoute>
                        {" "}
                        <Withdraw />{" "}
                      </ProtectedRoute>
                    }
                  ></Route>
  
                  <Route
                    path="/profile/wallet/deposit"
                    element={
                      <ProtectedRoute>
                        {" "}
                        <Deposit />{" "}
                      </ProtectedRoute>
                    }
                  ></Route>
                  <Route
                    path="/profile/safety"
                    element={
                      <ProtectedRoute>
                        {" "}
                        <Safety />{" "}
                      </ProtectedRoute>
                    }
                  ></Route>
                  <Route
                    path="/profile/verification"
                    element={
                      <ProtectedRoute>
                        {" "}
                        <Verification />{" "}
                      </ProtectedRoute>
                    }
                  ></Route>
                  <Route
                    path="/profile/main"
                    element={
                      <ProtectedRoute>
                        {" "}
                        <Profile />{" "}
                      </ProtectedRoute>
                    }
                  ></Route>
                </Routes>
                <MobileMenu />
                <Footer />
              </>
            )
          ) : (
            <>
              <div className="animation-Loading">
                <img src="/images/lotos.gif" style={{ width: "100%" }} alt="" />
              </div>
            </>
          )}
        </div>


        <FollowTelegramAlert />
      </>
    );
  }
  
  export default App;
  