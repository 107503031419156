import { useState } from "react";
import ProfileMenu from "../../../components/ProfileMenu";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { balanceAtom, phoneAtom } from "../../../App";
import { useAtom } from "jotai";
import { IMaskInput } from "react-imask";
export default function Withdraw() {
    const [t, i18n] = useTranslation();
    const [withdrawPhone, setWithdrawPhone] = useState("");
    const [bank, setBank] = useState("");
    const [wagerOpen, setWagerOpen] = useState(false);
    const [wdmethod, setWdmethod] = useState("Карта");
    const [balance, setBalance] = useAtom(balanceAtom);
    const [sum, setSum] = useState();
    const [data, setData] = useState("");
    const [wagerResult, setWagerResult] = useState(null);
    function formatNumber(number) {
        return number.toLocaleString("en-US");
    }
    const requestWager = () => {
        axios
            .get(process.env.REACT_APP_API + "/user/voyager", {
                headers: {
                    Authorization:
                        "Bearer " + localStorage.getItem("accessToken"),
                },
            })
            .then((response) => {
                setWagerResult(
                    `Прогресс отыгрыша: ${formatNumber(response.data.totalLoss) + " ₽" + " / " + formatNumber(response.data.voyager)} ₽`,
                );
            })
            .catch((error) => {
                console.log(error);
            });
    };
    requestWager();
    const [selectedCurrency, setSelectedCurrency] = useState("RUB");
    return (
        <>
            <div className="Profile-Block">
                <ProfileMenu url={"/profile/wallet/withdraw"} />
                <div className="Profile-Block__container">
                    <div className="Mobile-Selector">
                        <Link to="/profile/wallet/wallets">
                            {t("your_wallets")}
                        </Link>
                        <Link to="/profile/wallet/deposit">{t("deposit")}</Link>

                        <Link
                            to="/profile/wallet/withdraw"
                            className="selected"
                        >
                            {t("withdraw")}
                        </Link>
                        <Link to="/profile/wallet/history">История</Link>
                    </div>
                    <span>
                        {" "}
                        <p>{t("wallet")}</p>{" "}
                        <svg
                            width="10"
                            height="10"
                            viewBox="0 0 10 10"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M2.5 7.08329L4.58333 4.99996L2.5 2.91663M5.41667 7.08329L7.5 4.99996L5.41667 2.91663"
                                stroke="white"
                                stroke-opacity="0.3"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                        </svg>
                        <p>{t("withdraw")}</p>
                    </span>
                    <h1>{t("wallet")}</h1>
                    <div className="Profile-Block__fields">
                        <div>
                            <nav>
                                <p>{t("withdraw_from_wallet")}</p>
                                <div className="selector white-selector  ">
                                    <div
                                        onClick={(e) => {
                                            e.preventDefault();
                                            e.target.parentElement.classList.toggle(
                                                "open",
                                            );
                                        }}
                                        className="selector__header"
                                    >
                                        <p className="selector__value">
                                            <svg
                                                width="16"
                                                height="16"
                                                viewBox="0 0 16 16"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <circle
                                                    cx="8"
                                                    cy="8"
                                                    r="8"
                                                    fill="#CC3332"
                                                />
                                                <path
                                                    d="M5.97 12V4.86H7.85C8.72333 4.86 9.37 5.03667 9.79 5.39C10.2167 5.74333 10.43 6.25667 10.43 6.93C10.43 7.38333 10.3267 7.77333 10.12 8.1C9.91333 8.42 9.60667 8.66667 9.2 8.84C8.8 9.01333 8.30333 9.1 7.71 9.1H6.87V12H5.97ZM5.1 10.64V9.99H8.52V10.64H5.1ZM5.1 9.1V8.34H7.39V9.1H5.1ZM7.58 8.34C7.98667 8.34 8.33 8.29667 8.61 8.21C8.89667 8.12333 9.11667 7.98 9.27 7.78C9.42333 7.58 9.5 7.30667 9.5 6.96C9.5 6.50667 9.36 6.17 9.08 5.95C8.8 5.73 8.36333 5.62 7.77 5.62H6.87V8.34H7.58Z"
                                                    fill="white"
                                                />
                                            </svg>

                                            {balance.RUB.toFixed(
                                                2,
                                            ).toLocaleString("en-US")}
                                        </p>
                                        <svg
                                            width="17"
                                            height="17"
                                            viewBox="0 0 17 17"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M4.25 6.375L8.5 10.625L12.75 6.375"
                                                stroke="white"
                                                strokeWidth="2"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                            />
                                        </svg>
                                    </div>

                                    <div
                                        onClick={(e) => {
                                            if (e.target.nodeName == "A") {
                                                e.target.parentElement.parentElement.parentElement.classList.remove(
                                                    "open",
                                                );
                                                return;
                                            }
                                            if (
                                                e.target.classList.contains(
                                                    "selector__body",
                                                )
                                            )
                                                return;
                                            e.target.parentElement.parentElement.classList.remove(
                                                "open",
                                            );

                                            if (!e.target.nextSibling) return;
                                            e.target.parentElement.previousSibling.querySelector(
                                                "p",
                                            ).innerHTML = e.target.innerHTML;
                                        }}
                                        className="selector__body"
                                    >
                                        <div>
                                            <svg
                                                width="16"
                                                height="16"
                                                viewBox="0 0 16 16"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <circle
                                                    cx="8"
                                                    cy="8"
                                                    r="8"
                                                    fill="#CC3332"
                                                />
                                                <path
                                                    d="M5.97 12V4.86H7.85C8.72333 4.86 9.37 5.03667 9.79 5.39C10.2167 5.74333 10.43 6.25667 10.43 6.93C10.43 7.38333 10.3267 7.77333 10.12 8.1C9.91333 8.42 9.60667 8.66667 9.2 8.84C8.8 9.01333 8.30333 9.1 7.71 9.1H6.87V12H5.97ZM5.1 10.64V9.99H8.52V10.64H5.1ZM5.1 9.1V8.34H7.39V9.1H5.1ZM7.58 8.34C7.98667 8.34 8.33 8.29667 8.61 8.21C8.89667 8.12333 9.11667 7.98 9.27 7.78C9.42333 7.58 9.5 7.30667 9.5 6.96C9.5 6.50667 9.36 6.17 9.08 5.95C8.8 5.73 8.36333 5.62 7.77 5.62H6.87V8.34H7.58Z"
                                                    fill="white"
                                                />
                                            </svg>
                                            {balance.RUB.toFixed(
                                                2,
                                            ).toLocaleString("en-US")}
                                        </div>
                                    </div>
                                </div>
                                <p>{t("withdraw_method")}</p>
                                <div className="left-right-select">
                                    <div
                                        onClick={(e) => {
                                            e.target.parentElement
                                                .querySelector(".selected")
                                                .classList.remove("selected");
                                            e.target.classList.add("selected");
                                            setWdmethod("Карта");
                                        }}
                                        className="selected"
                                    >
                                        <svg
                                            width={11}
                                            height={8}
                                            viewBox="0 0 11 8"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <g clipPath="url(#clip0_220_664)">
                                                <path
                                                    d="M9.58114 8H1.41886C1.04344 8 0.683332 7.85994 0.417393 7.61048C0.151454 7.36103 0.00136513 7.02252 0 6.6691V1.32605C0.0027238 0.973467 0.153412 0.636202 0.419204 0.387795C0.684996 0.139388 1.04433 -9.32433e-06 1.41886 4.67797e-10L9.58114 4.67797e-10C9.95745 4.67798e-10 10.3183 0.140732 10.5844 0.391236C10.8505 0.64174 11 0.981496 11 1.33576V6.67881C10.9959 7.03055 10.8446 7.36657 10.579 7.61393C10.3133 7.86129 9.95478 8.00002 9.58114 8ZM1.41886 0.728598C1.24781 0.728598 1.08377 0.792566 0.962818 0.906432C0.841869 1.0203 0.773921 1.17473 0.773921 1.33576V6.67881C0.773921 6.83984 0.841869 6.99428 0.962818 7.10814C1.08377 7.22201 1.24781 7.28597 1.41886 7.28597H9.58114C9.7513 7.28598 9.91457 7.22268 10.0354 7.10986C10.1562 6.99704 10.2247 6.84385 10.2261 6.68367V1.34062C10.2268 1.26048 10.2106 1.181 10.1785 1.10678C10.1464 1.03255 10.099 0.965042 10.039 0.908146C9.97906 0.85125 9.90773 0.806092 9.82915 0.775275C9.75056 0.744459 9.66627 0.728595 9.58114 0.728598H1.41886Z"
                                                    fill="white"
                                                />
                                                <path
                                                    d="M10.6128 2.5647H0.386719V3.29329H10.6128V2.5647Z"
                                                    fill="white"
                                                />
                                                <path
                                                    d="M4.44216 5.64901H2.96655C2.86392 5.64901 2.7655 5.61063 2.69293 5.54231C2.62036 5.47399 2.57959 5.38133 2.57959 5.28471C2.57959 5.18809 2.62036 5.09543 2.69293 5.02711C2.7655 4.95879 2.86392 4.92041 2.96655 4.92041H4.44216C4.54479 4.92041 4.64321 4.95879 4.71578 5.02711C4.78835 5.09543 4.82912 5.18809 4.82912 5.28471C4.82912 5.38133 4.78835 5.47399 4.71578 5.54231C4.64321 5.61063 4.54479 5.64901 4.44216 5.64901Z"
                                                    fill="white"
                                                />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_220_664">
                                                    <rect
                                                        width={11}
                                                        height={8}
                                                        fill="white"
                                                    />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                        <p>Карта</p>
                                    </div>
                                    <div
                                        onClick={(e) => {
                                            e.target.parentElement
                                                .querySelector(".selected")
                                                .classList.remove("selected");
                                            e.target.classList.add("selected");
                                            setWdmethod("Крипта");
                                        }}
                                    >
                                        <svg
                                            width="11"
                                            height="11"
                                            viewBox="0 0 11 11"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <g clip-path="url(#clip0_220_671)">
                                                <path
                                                    d="M5.50283 8.1183C5.10192 8.1183 4.71744 7.95904 4.43395 7.67556C4.15047 7.39207 3.99121 7.00759 3.99121 6.60668C3.99121 6.49852 4.03418 6.39479 4.11066 6.31832C4.18714 6.24184 4.29086 6.19887 4.39902 6.19887C4.50718 6.19887 4.61091 6.24184 4.68739 6.31832C4.76387 6.39479 4.80683 6.49852 4.80683 6.60668C4.80683 6.74434 4.84765 6.8789 4.92413 6.99336C5.00061 7.10781 5.10931 7.19702 5.23648 7.2497C5.36366 7.30238 5.5036 7.31616 5.63861 7.2893C5.77362 7.26245 5.89763 7.19616 5.99497 7.09882C6.09231 7.00149 6.1586 6.87747 6.18545 6.74246C6.21231 6.60745 6.19852 6.46751 6.14584 6.34033C6.09317 6.21316 6.00396 6.10446 5.8895 6.02798C5.77505 5.9515 5.64048 5.91068 5.50283 5.91068C5.20386 5.91068 4.9116 5.82203 4.66302 5.65593C4.41443 5.48983 4.22069 5.25375 4.10628 4.97754C3.99187 4.70133 3.96193 4.39739 4.02026 4.10417C4.07858 3.81094 4.22255 3.5416 4.43395 3.33019C4.64536 3.11879 4.9147 2.97482 5.20793 2.9165C5.50115 2.85817 5.80509 2.88811 6.0813 3.00252C6.35751 3.11693 6.59359 3.31067 6.75969 3.55926C6.92579 3.80784 7.01444 4.1001 7.01444 4.39907C7.01444 4.50723 6.97148 4.61095 6.895 4.68743C6.81852 4.76391 6.71479 4.80688 6.60663 4.80688C6.49848 4.80688 6.39475 4.76391 6.31827 4.68743C6.24179 4.61095 6.19882 4.50723 6.19882 4.39907C6.19882 4.26141 6.158 4.12685 6.08153 4.01239C6.00505 3.89794 5.89635 3.80873 5.76917 3.75605C5.642 3.70337 5.50206 3.68959 5.36705 3.71645C5.23204 3.7433 5.10802 3.80959 5.01068 3.90692C4.91335 4.00426 4.84706 4.12828 4.82021 4.26329C4.79335 4.3983 4.80713 4.53824 4.85981 4.66541C4.91249 4.79259 5.0017 4.90129 5.11615 4.97777C5.23061 5.05424 5.36517 5.09506 5.50283 5.09506C5.90373 5.09506 6.28822 5.25432 6.5717 5.53781C6.85519 5.82129 7.01444 6.20578 7.01444 6.60668C7.01444 7.00759 6.85519 7.39207 6.5717 7.67556C6.28822 7.95904 5.90373 8.1183 5.50283 8.1183Z"
                                                    fill="#7762CD"
                                                />
                                                <path
                                                    d="M5.50302 3.70303C5.39487 3.70303 5.29114 3.66006 5.21466 3.58358C5.13818 3.5071 5.09521 3.40338 5.09521 3.29522V2.71885C5.09521 2.61069 5.13818 2.50696 5.21466 2.43048C5.29114 2.354 5.39487 2.31104 5.50302 2.31104C5.61118 2.31104 5.71491 2.354 5.79139 2.43048C5.86787 2.50696 5.91084 2.61069 5.91084 2.71885V3.29522C5.90943 3.40294 5.86601 3.50585 5.78983 3.58203C5.71366 3.6582 5.61075 3.70162 5.50302 3.70303Z"
                                                    fill="#7762CD"
                                                />
                                                <path
                                                    d="M5.50302 8.69992C5.39487 8.69992 5.29114 8.65695 5.21466 8.58047C5.13818 8.504 5.09521 8.40027 5.09521 8.29211V7.7103C5.09521 7.60214 5.13818 7.49841 5.21466 7.42193C5.29114 7.34546 5.39487 7.30249 5.50302 7.30249C5.61118 7.30249 5.71491 7.34546 5.79139 7.42193C5.86787 7.49841 5.91084 7.60214 5.91084 7.7103V8.28667C5.91084 8.39534 5.86805 8.49963 5.79173 8.57698C5.7154 8.65432 5.61168 8.69849 5.50302 8.69992Z"
                                                    fill="#7762CD"
                                                />
                                                <path
                                                    d="M5.50279 11.0001C4.41399 11.0011 3.34934 10.6792 2.44359 10.075C1.53784 9.47073 0.831703 8.61137 0.414541 7.60566C-0.00262015 6.59995 -0.112051 5.49308 0.1001 4.42516C0.31225 3.35723 0.836444 2.37624 1.60634 1.60634C2.37624 0.836444 3.35723 0.31225 4.42516 0.1001C5.49308 -0.112051 6.59995 -0.00262015 7.60566 0.414541C8.61137 0.831703 9.47073 1.53784 10.075 2.44359C10.6792 3.34934 11.0011 4.41399 11.0001 5.50279C10.9972 6.95987 10.4171 8.35646 9.38678 9.38678C8.35646 10.4171 6.95987 10.9972 5.50279 11.0001ZM5.50279 0.815687C4.57553 0.814611 3.6688 1.08859 2.89729 1.60296C2.12579 2.11733 1.52419 2.84898 1.1686 3.70534C0.813007 4.5617 0.71941 5.50429 0.899648 6.41385C1.07989 7.32342 1.52586 8.15909 2.18115 8.81514C2.83643 9.47118 3.67158 9.91813 4.58094 10.0994C5.49029 10.2807 6.43299 10.1882 7.28976 9.83362C8.14654 9.47902 8.87888 8.87827 9.39415 8.10736C9.90942 7.33645 10.1844 6.43004 10.1844 5.50279C10.183 4.26107 9.68948 3.07055 8.81196 2.19201C7.93445 1.31348 6.7445 0.818563 5.50279 0.815687Z"
                                                    fill="#7762CD"
                                                />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_220_671">
                                                    <rect
                                                        width="11"
                                                        height="11"
                                                        fill="white"
                                                    />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                        <p>Крипта</p>
                                    </div>
                                </div>
                                {wdmethod === "Крипта" && (
                                    <div className="selector">
                                        <div
                                            onClick={(e) => {
                                                e.preventDefault();
                                                e.target.parentElement.classList.toggle(
                                                    "open",
                                                );
                                            }}
                                            className="selector__header"
                                        >
                                            <p className="selector__value">
                                                Tether TRC20
                                            </p>
                                            <svg
                                                width="24"
                                                height="24"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M6 9L12 15L18 9"
                                                    stroke="#7762CD"
                                                    stroke-width="2"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                />
                                            </svg>
                                        </div>
                                        {wdmethod === "Крипта" && (
                                            <div
                                                onClick={(e) => {
                                                    if (
                                                        e.target.classList.contains(
                                                            "selector__body",
                                                        )
                                                    )
                                                        return;
                                                    e.target.parentElement.previousSibling.querySelector(
                                                        "p",
                                                    ).innerHTML =
                                                        e.target.innerHTML;
                                                    e.target.parentElement.parentElement.classList.remove(
                                                        "open",
                                                    );
                                                    setSelectedCurrency(
                                                        e.target.textContent,
                                                    );
                                                }}
                                                className="selector__body"
                                            >
                                                <div>Tether TRC20</div>
                                                <div>Tron</div>
                                                <div>Ethereum</div>
                                                <div>Bitcoin</div>
                                                <div>Litecoin</div>
                                            </div>
                                        )}
                                    </div>
                                )}

                                <p>
                                    {wdmethod === "Карта" ? (
                                        <> {t("card_number")} </>
                                    ) : (
                                        <> {t("address")}</>
                                    )}
                                </p>
                                <input
                                    value={data}
                                    onChange={(e) => {
                                        setData(e.target.value);
                                    }}
                                    type="text"
                                    placeholder={
                                        wdmethod === "Карта"
                                            ? "0000 0000 0000 0000"
                                            : "Адрес"
                                    }
                                    id=""
                                />
                                <p>
                                    {t("amount")}, {selectedCurrency}
                                </p>
                                <input
                                    value={sum}
                                    onChange={(e) => {
                                        setSum(e.target.value);
                                    }}
                                    type="number"
                                    placeholder="Сумма"
                                />
                                {wdmethod === "Карта" && (
                                    <>
                                        <p>Банк</p>
                                        <input
                                            type="text"
                                            value={bank}
                                            onChange={(e) => {
                                                setBank(e.target.value);
                                            }}
                                            placeholder="Введите ваш банк"
                                        />
                                        <p>Номер телефона</p>
                                        <IMaskInput
                                            mask="+{7}(000)000-00-00"
                                            value={withdrawPhone}
                                            onChange={(e) => {
                                                setWithdrawPhone(
                                                    e.target.value,
                                                );
                                            }}
                                            onPaste={(e) => {
                                                setWithdrawPhone(
                                                    e.target.value,
                                                );
                                            }}
                                            type="text"
                                            placeholder="Введите ваш номер телефона"
                                        />
                                    </>
                                )}
                                <a
                                    onClick={() => {
                                        if (Number(sum) < 500) {
                                            toast.error(
                                                "Минимальная сумма для вывода - 500 RUB",
                                            );
                                            return;
                                        }

                                        if(wagerResult != null) {
                                            toast.error("Вывод недоступен! Найдены активные бонусы");
                                            return;   
                                        }

                                        if (balance.RUB < Number(sum)) {
                                          toast.error("Недостаточно средств!");
                                          return;
                                        }
                                        if (withdrawPhone.length != 16 && wdmethod == "Карта") {
                                          toast.error("Введите корректный номер телефона!");
                                          return;
                                        }

                                        if (
                                            bank.length == 0 &&
                                            wdmethod == "Карта"
                                        ) {
                                            toast.error("Введите банк!");
                                            return;
                                        }

                                        const cardData = {
                                            amount: Number(sum),
                                            method: "card",
                                            currency: "RUB",
                                            phone: withdrawPhone.replace(/[-()\s]/g, ""),
                                            // phone: withdrawPhone,
                                            bank: bank,
                                            payment_details: { card: data },
                                        };
                                        const cryptoData = {
                                            amount: Number(sum),
                                            method: "card",
                                            currency: selectedCurrency,
                                            payment_details: {
                                                crypto_address: data,
                                            },
                                        };

                                        axios
                                            .post(
                                                process.env.REACT_APP_API +
                                                    "/user/withdraw",
                                                wdmethod == "Карта"
                                                    ? cardData
                                                    : cryptoData,
                                                {
                                                    headers: {
                                                        Authorization:
                                                            "Bearer " +
                                                            localStorage.getItem(
                                                                "accessToken",
                                                            ),
                                                    },
                                                },
                                            )
                                            .then((response) => {
                                                if (
                                                    response.data.status ===
                                                    "pending"
                                                ) {
                                                    toast.success(
                                                        "Заявка на вывод подана и находится на рассмотрении!",
                                                    );
                                                    setData("");
                                                    setWithdrawPhone("");
                                                    setBank("");
                                                    setSum("");
                                                }

                                                if (
                                                    response.data.cause ===
                                                    "banned"
                                                ) {
                                                    toast.error(
                                                        "Your account is banned",
                                                    );
                                                }
                                            })
                                            .catch((err) => {
                                                if (
                                                    err.response.status == 409
                                                ) {
                                                    setWagerOpen(true);
                                                }
                                            });
                                    }}
                                    className="btn"
                                >
                                    {t("withdraw")}
                                </a>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
            {wagerOpen && (
                <div
                    className="popup--fs open"
                    onClick={() => setWagerOpen(false)}
                >
                    <div
                        className="wager--form"
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                    >
                        <svg
                            className="close-popup-svg"
                            onClick={() => {
                                setWagerOpen(false);
                            }}
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M17 7L7 17M7 7L17 17"
                                stroke="white"
                                stroke-opacity="0.8"
                                strokeWidth="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                        </svg>
                        <h3>Вывод недоступен</h3>
                        <p>Причина: Вы не завершили отыгрыш средств</p>
                        <br />
                        <br />
                        <p>{wagerResult}</p>
                        {wagerResult == null && (
                            <a
                                onClick={requestWager}
                                className="btn"
                                to="/profile/wallet/wallets"
                            >
                                Проверить отыгрыш
                            </a>
                        )}
                    </div>
                </div>
            )}
        </>
    );
}