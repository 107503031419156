import { useAtom } from "jotai";
import React from "react";
import { isTelegramAtom } from "../App";

export default function Support() {
    const [isTelegram] = useAtom(isTelegramAtom);

    const openLink = () => {
        if(isTelegram) {
            window.Telegram.WebApp.openLink("https://t.me/lotos_support_bot");
        } else {
            window.location.href="https://t.me/lotos_support_bot";
        }
    }

    return (

        <div className="support-box" onClick={() => openLink()}>
            <svg fill="#000000" height="800px" width="800px" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" 
                viewBox="0 0 295.511 295.511" >
            <path d="M295.511,177.42c0-26.245-16.105-47.776-36.381-49.409c-0.427-0.554-0.872-1.093-1.336-1.615
                c-0.18-29.195-11.613-58.598-31.441-80.775C205.198,21.96,177.285,8.929,147.755,8.929s-57.444,13.031-78.6,36.692
                c-19.828,22.178-31.261,51.581-31.441,80.776c-0.463,0.522-0.908,1.06-1.334,1.614C16.106,129.642,0,151.174,0,177.42
                c0,26.249,16.107,47.782,36.382,49.412c5.447,7.068,13.834,11.635,23.428,11.635c16.414,0,29.612-13.354,29.612-29.767v-62.56
                c0-13.547-8.944-25.001-21.351-28.591c4.628-42.865,39.881-78.62,79.606-78.62c39.725,0,74.761,35.756,79.389,78.621
                c-12.406,3.591-21.644,15.045-21.644,28.591v62.56c0,14.988,11.315,27.234,25.747,29.281c-5.93,9.906-16.351,14.601-31.795,14.601
                h-3.055c-5.94,0-12.254-0.358-18.102-1.106c-3.588-9.893-13.08-16.894-24.197-16.894h-9.01c-14.188,0-25.729,11.812-25.729,26
                s11.542,26,25.729,26h9.01c5.911,0,11.358-2.365,15.707-5.729c12.84,1.987,24.466,1.729,26.592,1.729h3.055
                c21.055,0,38.435-6.493,50.26-19.126c9.19-9.818,14.761-22.559,16.658-38.149C283.071,219.83,295.511,200.489,295.511,177.42z"/>
            </svg>


        </div>
    )

}