import { useAtom } from "jotai";
import React, { useEffect, useState } from "react";
import { isTelegramAtom } from "../App";

export default function FollowTelegramAlert() {
    const [isTelegram] = useAtom(isTelegramAtom);
    const [open, setOpen] = useState(false);

    function handleClose(e, s) {
        e.stopPropagation();

        if (s) {
            localStorage.setItem("tg-subscribed", true);
        }

        setOpen(false);
    }

    function sub() {
        localStorage.setItem("tg-subscribed", true);

        window.Telegram.WebApp.openLink("https://t.me/lotoscasino");
    }

    useEffect(() => {
        if (!localStorage.getItem("tg-subscribed")) {
            setOpen(true);
        }
    }, []);

    if (!isTelegram) return;

    return (
        <div className={`alert-tg ${open && "active"}`} onClick={handleClose}>
            <div className="content" onClick={(e) => e.stopPropagation()}>
                <div className="close" onClick={(e) => handleClose(e, true)}>
                    <svg
                        width="12"
                        height="12"
                        viewBox="0 0 12 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M11.7102 10.2899C11.8985 10.4782 12.0043 10.7336 12.0043 10.9999C12.0043 11.2662 11.8985 11.5216 11.7102 11.7099C11.5219 11.8982 11.2665 12.004 11.0002 12.004C10.7339 12.004 10.4785 11.8982 10.2902 11.7099L6.00019 7.40994L1.71019 11.7099C1.52188 11.8982 1.26649 12.004 1.00019 12.004C0.733884 12.004 0.478489 11.8982 0.290185 11.7099C0.101882 11.5216 -0.00390625 11.2662 -0.00390625 10.9999C-0.00390625 10.7336 0.101882 10.4782 0.290185 10.2899L4.59019 5.99994L0.290185 1.70994C0.101882 1.52164 -0.00390625 1.26624 -0.00390625 0.999941C-0.00390625 0.73364 0.101882 0.478245 0.290185 0.289941C0.478489 0.101638 0.733884 -0.00415039 1.00019 -0.00415039C1.26649 -0.00415039 1.52188 0.101638 1.71019 0.289941L6.00019 4.58994L10.2902 0.289941C10.4785 0.101638 10.7339 -0.00415039 11.0002 -0.00415039C11.2665 -0.00415039 11.5219 0.101638 11.7102 0.289941C11.8985 0.478245 12.0043 0.73364 12.0043 0.999941C12.0043 1.26624 11.8985 1.52164 11.7102 1.70994L7.41019 5.99994L11.7102 10.2899Z"
                            fill="white"
                        />
                    </svg>
                </div>

                <div className="t" style={{ marginBottom: 50, marginTop: 40 }}>
                    ПОДПИСАТЬСЯ НА КАНАЛ <span>LOTOSCASINO</span>
                </div>

                <button style={{ marginBottom: 40 }} onClick={sub}>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="white"
                        width="30px"
                        height="30px"
                        viewBox="0 0 24 24"
                    >
                        <path d="m20.665 3.717-17.73 6.837c-1.21.486-1.203 1.161-.222 1.462l4.552 1.42 10.532-6.645c.498-.303.953-.14.579.192l-8.533 7.701h-.002l.002.001-.314 4.692c.46 0 .663-.211.921-.46l2.211-2.15 4.599 3.397c.848.467 1.457.227 1.668-.785l3.019-14.228c.309-1.239-.473-1.8-1.282-1.434z" />
                    </svg>
                    <span>Subscribe</span>
                </button>
                <p style={{ marginBottom: 20 }}>
                    Подписывайтесь на наш канал в Telegram и будьте в курсе всех новостей и акций
                </p>
            </div>
        </div>
    );
}
