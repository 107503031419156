import i18n from "i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: false,
    fallbackLng: "ru",
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: {
          text: "English",
          log_in: "Log in",
          
          register: "Register",
          currentLanguage: "EN",
          search_placeholder: "Search",
          page_not_found: "Page not found!",
          to_main_page: "Main",
          save: "Save",
          link_copied: 'Link copied',
          completed: "Completed",
          send_code: "Send code",
          waiting_confirmation: "Waiting for confirmation",
          pass_verification: "Pass verification",
          not_requested: "Not required",
          next: "Next",
          send: "Send",
          load_more: "Load more",
          all_providers: "All providers",
          best: "Best",
          new: "New",
          your_partner_link: 'Your partner link',
          bonuses: "Bonuses",
          cards: "Cards",
          live: "Live",
          fast: "Fast",
          profile: "Profile",
          favourite: "Favourite",

          main: "Main",
          country: "Country",
          username: "Username",
          city: "City",
          name: "First name",
          address: "Address",
          last_name: "Last name",
          post_index: "Postal code",
          birth_date: "Birth date",
          mobile_phone: "Phone",
          success: 'Success',
          waiting: 'Waiting',
          cancelled: 'Cancelled',
          gender: "Gender",
          gender_male: "Male",
          gender_female: "Female",
          bonus_autoactivation: "Bonus autoactivation",
          enter_your_email: "Enter your e-mail",
          enter_your_city: "Enter your city",
          enter_your_username: "Enter your username",
          enter_your_name: "Enter your name",
          enter_your_last_name: "Enter your last name",
          enter_your_address: "Enter your address",
          data_updated: 'Profile data has been successfully updated!',
          enter_your_post_index: "Enter your postal code",
          verification: "Verification",
          email_verification: "E-mail verification",
          phone_verification: "Phone verification",
          documents_verification: "Document verification",
          documents_verification_desc: `Passport, driver's license or other official document replacing them.`,
          documents_verification_step1: "Step 1: The main document",
          documents_verification_step2: "Step 2: Photo with document",
          documents_verification_step1_requirements: `Passport, driver's license or official government-issued ID card. There must be a passport, driver's license or other official identification issued by the state. It is necessary to obtain both the front and back of the identity card. The document must be up-to-date and not overdue. The document must specify the expiration date. The document must contain your unmodified photo. Your date of birth must be indicated in the document. Watermarks on documents should be visible.`,
          documents_verification_step2_requirements: `Your selfie with your passport/ID card in your hands. It should be a single photo, so make sure that your face and passport details are well recognizable and do not overlap.`,
          requirements: "Requirements",
          users: 'Users',
            card_number: 'Card number',
          referral_desc: 'Start earning and receiving passive income with Lotos casino, our multi-level referral system allows you to earn a good bank every week, all you need to do is send a link to several friends.',
          deposit_method: 'Deposit method',
          documents_verification_requirements:
            "Document Verification requirements",
           pay:'Pay',
            levels: 'Levels',
            birthday_gifts: 'Birthday gifts',
            fastWithdraw: 'Quick withdraws (under 5 min.)',
            fullDaySupport: '24/7 Live Support',
            vipManager: 'VIP Manager',
            vip_rules: 'VIP Rules',
            credentials: 'Credentials',
            status: 'Status',
          address_verification: "Address verification",
          address_verification_desc:
            "A utility bill, phone bill, or bank statement that contains your full name and address.",
          payment_verification: "Verification of the payment method",
          payment_verification_desc:
            "Screenshot or photo from online banking, bank statement, Skrill page, etc. showing the deposit. Required only upon request from the administration.",
          choose: "Select",
          wallet_history: 'Wallet history',
          withdraw_from_wallet: 'Withdraw from wallet',
          balance: 'Balance',
          choose_main_wallet: 'Select main wallet',
          wallet_desc: 'All currencies will be displayed in the currency of the main wallet. Cashback is credited only to the main wallet.',
          or_drag_image: "or drag the image",
          safety: "Safety",
          wallet_stats: 'Wallet stats',
          withdraw_method: 'Withdraw method',
          change_password: "Change password",
          stats: 'Stats',
          old_password: "Old password",
          new_password: "New password",
          confirm_password: "Confirm password",
          change: "Change",
          all_wallets: 'My wallets',
          connect: "Connect",
          connected: "Connected",
          use_promocode: "Use promocode",
          not_connected: "Not connected",
          scan_qr: "Scan the QR code in the 2FA app",
          notifications: "Notifications",
          games: 'Games',
          menu: 'Menu',
          self_limitation: "Responsible gambling",
          bonuses_list: 'Bonuses list',
          faq_desc_p1: "If your question is not on the list, you can contact support",
faq: 'FAQ',

bonuses_desc: 'You should carefully review these Terms and Conditions, as they contain important information about your rights and obligations related to the use of the Website and form a legally binding agreement between you - our client ("Client") - and us. By using this Website and/or accessing the services, you, regardless of whether you are a guest or a registered user with an account ("Account"), agree to be bound by these Terms, as well as any amendments that may be published from time to time. If you do not accept these Terms, you should refrain from accessing the Service and using the Website.',
          
          self_limitation_desc:
            "Do you feel the need to block access to your account? You will not be able to log in to your account during the set period. You will also be excluded from all promotional offers.",
          choose_limitation_time: "Select the self-exclusion period",
          t1year: "1 year",
          t1month: "1 month",
          t2week: "2 weeks",
          t1week: "1 week",
          t3day: "3 days",
          t1day: "1 day",
          confirm: "Confirm",
          lost_by_users: `User's bets`,
          users_not_found: 'No users found',
          self_exclusion_active: "Self-exclusion active. Remaining: ",
          date: "Date",
          game: "Game",
          bet: "Bet",
          history: "Game history",
          deposit_history: 'History',
          password: "Password",
          information: "Information",
          payments: "Payments",
          terms: "Terms",
          privacy_policy: "Privacy Policy",
          bonuses: "Bonuses",
          about: "About",
          all_games: "Games",
          vip_club: "VIP-Club",
          help: "Help",
          support: "Support",
          log_out: "Log out",
          reload: "Reload",
          wallet: "Wallet",
          trade: "Trade",
          all_time: 'All the time',
          daily_limit: 'Daily withdraw limit',

          vip_rules_text: `You should carefully review these Terms and Conditions, as they contain important information about your rights and obligations related to the use of the Website and form a legally binding agreement between you - our client ("Client") - and us. By using this Website and/or accessing the services, you, regardless of whether you are a guest or a registered user with an account ("Account"), agree to be bound by these Terms, as well as any amendments that may be published from time to time. If you do not accept these Terms, you should refrain from accessing the Service and using the Website.`,
          available_promotions: "Available promotions",
          footer_desc:
            "LOTOS Casino is owned and operated by Perfect Storm B.V. (registration number 150536) - Corporaalweg 10, Willemstad, Curacao.",
            deposit_history: "History",
            referral: 'Referral system',
            ref_desc: 'Cashback is paid weekly to the account',
          your_wallets: "Your wallets",
          this_week: 'This week',
          deposit: "Deposit",
          withdraw: "Withdraw",
          phone: "Phone",
          email: "E-mail",
          payments: "Payments",
          deposit_methods: "Deposit methods",
          withdraw_methods: "Withdraw methods",
          earned_from_users: 'Your comission',
          no: "No",
          refill_wallet: "Top up your wallet",
          payments_description:
            "The Lotos Casino team processes withdrawals within five minutes after a request is submitted. After that, the transfer of funds to your account will depend on the payment system to which the withdrawal was requested.",
          method: "Method",
          type: "Type",
          top_users: 'Top users',
          deposit_sum: "Deposit sum",
          withdraw_sum: "Withdraw sum",
         blocked_by_bonuses: 'Earned from referral system',
          comission: "Comission",
          process_time: "Processing time",
          transaction_limit: "Transaction Limit",
          instantly: "Instantly",
          error_occurred: "An error has occurred!",
          invalid_password: "Invalid password!",
          need_login: "To perform this action, you need to log in!",
          no_games_found: "No games found!",
          forgot_password: "Forgot password?",
          code_is_incorrect: "Code is incorrect!",
          code_was_sent: "The code has been sent to your phone number!",
          code_was_sent_email: "The code has been sent to your email address!",
          insert_email_or_phone: "Insert E-mail or phone number",
          insert_your_password: "Insert your password",
          confirmation_code: "Confirmation code",
          find_a_game: "Find a game",
          validation_error: "Check the correctness of the entered data!",
          email_to_recovery: "Email for recovery",
          insert_email: "Insert E-mail",
          email_not_registered: "There is no user with such an email address",
          password_changed: "You have successfully changed your password!",
          incorrect_restore_link: "The password recovery link is invalid!",
          password_recovery: "Password recovery",
          session: "Session",
          passwords_not_match: `Passwords don't match!`,
          history_is_clear: "History is clear!",
          amount: "Amount",
          send_balance: "Send balance",
          select_your_country: "Select your country",
        },
      },
      ru: {
        translation: {
          data_updated: 'Данные профиля были обновлены!',
          select_your_country: "Выберите страну",
          log_in: "Войти",
          password: "Пароль",
          register: "Регистрация",
          text: "Русский",
          currentLanguage: "RU",
          search_placeholder: "Поиск",
          page_not_found: "Страница не найдена!",
          to_main_page: "На главную",
          save: "Сохранить",
          completed: "Пройдена",
          send_code: "Отправить код",
          waiting_confirmation: "Ожидание подтверждения",
          pass_verification: "Пройти верификацию",
          not_requested: "Не требуется",
          next: "Далее",
          send: "Отправить",
          load_more: "Загрузить больше",
          success: 'Успешно',
          waiting: 'Ожидание',
          all_providers: "Все провайдеры",
          best: "Лучшие",
          new: "Новые",
          card_number: 'Номер карты',
          bonuses_: "Бонусы",
          cards: "Карты",
          live: "Лайв",
          fast: "Быстрые",
          cancelled: 'Отменен',
          profile: "Профиль",

          favourite: "Избранное",
          main: "Основное",
          country: "Страна",
          username: "Имя пользователя",
          city: "Город",
          name: "Имя",
          address: "Адрес",
          last_name: "Фамилия",
          post_index: "Почтовый индекс",
          birth_date: "Дата рождения",
          mobile_phone: "Телефон",
          gender: "Пол",
          gender_male: "Мужчина",
          gender_female: "Женщина",
          bonus_autoactivation: "Автоактивация бонусов",
          enter_your_username: "Введите имя пользователя",
          enter_your_email: "Введите ваш e-mail",
          enter_your_city: "Введите ваш город",
          enter_your_name: "Введите ваше имя",
          enter_your_last_name: "Введите вашу фамилию",
          enter_your_address: "Введите ваш адрес",
          enter_your_post_index: "Введите свой почтовый индекс",
bonuses_list: 'Список акций',
          verification: "Верификация",
          email_verification: "Верификация E-mail",
          phone_verification: "Верификация телефона",
          documents_verification: "Верификация по документам",
          documents_verification_desc:
            "Паспорт, водительское удостоверение или другой официальный документ, их заменяющий.",
          documents_verification_step1: "Шаг 1: Основной документ",
          documents_verification_step2: "Шаг 2:  Фото с документом",
          documents_verification_step1_requirements:
            "Паспорт, водительские права или официальное удостоверение личности государственного образца. Должен быть паспорт, водительские права или другое официальное удостоверение личности, выданное государством. Необходимо получить как лицевую, так и оборотную сторону удостоверения личности. Документ должен быть актуальным и не просроченным. В документе должен быть указан срок действия. В документе должна быть ваша неизмененная фотография. В документе должна быть указана дата вашего рождения. Водяные знаки на документах должны быть видны.",
          documents_verification_step2_requirements:
            "Ваше селфи с паспортом/удостоверением личности в руках. Это должна быть одна фотография, поэтому убедитесь, что ваше лицо и паспортные данные хорошо узнаваемы и не накладываются друг на друга.",
          requirements: "Требования",
          documents_verification_requirements:
            "Требования для верификации по документам",

          address_verification: "Верификация адреса",
          address_verification_desc:
            "Счет за коммунальные услуги, телефонный счет или выписка из банка, в которых полностью указаны ваше имя и адрес.",
          payment_verification: "Верификация средства оплаты",
          payment_verification_desc:
            "Скриншот или фотография из онлайн-банка, выписка с банковского счета, страница Skrill и т. д., показывающая депозит. Требуется только по запросу от администрации.",
          choose: "Выберите",
          or_drag_image: "или перетащите изображение",

          safety: "Безопасность",
          change_password: "Сменить пароль",
          old_password: "Старый пароль",
          new_password: "Новый пароль",
          confirm_password: "Подтвердите пароль",
          change: "Изменить",
          connect: "Подключить",
          connected: "Подключена",
          not_connected: "Не подключена",
          scan_qr: "Отсканируйте QR-код в приложении 2FA",
          notifications: "Уведомления",
          self_limitation: "Ответственная игра",
          self_limitation_desc:
            "Чувствуете необходимость заблокировать доступ к своей учетной записи? В течение установленного периода вы не сможете войти в свой аккаунт. Также вы будете исключены из всех рекламных предложений.",
          choose_limitation_time: "Выберите срок самоисключения",
          t1year: "1 год",
          t1month: "1 месяц",
          t2week: "2 недели",
          t1week: "1 неделя",
          t3day: "3 дня",
          t1day: "1 день",
          confirm: "Подтвердить",
          self_exclusion_active: "Самоисключение активно. Осталось: ",
          date: "Дата",
          game: "Игра",
          bet: "Ставка",
          history: "История игр",
          passwords_not_match: "Пароли не совпадают!",
          information: "Информация",
          payments: "Платежи",
          terms: "Условия и положения",
          privacy_policy: "Политика конфиденциальности",
          bonuses: "Акции",
          about: "О нас",
          all_games: "Все игры",
          games: 'Игры',
          vip_club: "VIP-Клуб",
          help: "Помощь",
          support: "Поддержка",
          log_out: "Выйти из аккаунта",
          history: "История игр",
          reload: "Релоад",
          users: 'Пользователей',
          users_not_found: 'Пользователей не найдено',
          wallet: "Кошелёк",
          trade: "Обмен",
          choose_main_wallet: 'Выбор основного кошелька',
         
          footer_desc:
            "LOTOS Casino принадлежит и управляется Perfect Storm B.V (registration number 150536) - Korporaalweg 10, Willemstad, Curaçao.",

          your_wallets: "Все кошельки",
          earned_from_users: 'Ваша комиссия',
          lost_by_users: 'Ставки рефералов',
          referral_desc: 'Начните зарабатывать с Lotos Casino! Наша многоуровневая реферальная система позволяет получать доход, просто отправив ссылку друзьям. Чем больше приглашений, тем больше ваш заработок. Присоединяйтесь и начните получать пассивный доход уже сегодня!',
          deposit: "Депозит",
          stats: 'Статистика',
          withdraw: "Вывод",
          menu: 'Меню',
          phone: "Телефон",
          all_time: 'Всё время',
          balance: 'Баланс',
          deposit_sum: "Сумма депозитов",
          withdraw_sum: "Сумма выводов",
         blocked_by_bonuses: 'Заработано по реферальной системе',
          email: "E-mail",
          your_partner_link: 'Партнерская ссылка',
          top_users: 'Топ пользователей',
          wallet_desc: 'Все валюты будут отображаться в валюте основного кошелька. Кэшбэк начисляется только на основной кошелек.',
          vip_rules: 'VIP-Правила',
          vip_rules_text: `Хотите максимум удовольствия от игры? Делайте ставки в наших слотах, получайте очки CP и повышайте свой статус, чтобы открывать уникальные награды и бонусы. На начальных уровнях вас ждут фриспины и денежные призы. Продвигаясь дальше, вы будете получать до 17% еженедельного кэшбэка. \n
На высших Элитных уровнях вас поддержит персональный менеджер, вы получите доступ к закрытым событиям и щедрым персональным подаркам. Наша программа лояльности - пожизненная. Повышайте статус, накапливая CP за ставки по курсу до 1 CP за 20 EUR. Присоединяйтесь сейчас и играйте, чтобы открыть для себя все привилегии и щедрость нашей программы! \n
Бонусы программы лояльности прогрессивные:при повышении уровня вы получаете более выгодные предложения и бонусы.\n
Накопленный прогресс не сбрасывается.\n
Игроки могут претендовать на еженедельный кэшбэк в форме бонусных средств, начисляемых по понедельникам.\n
Размер кэшбэка зависит от суммы проигрыша за последние 7 дней и увеличивается с повышением VIP-уровня.\n
Для получения кэшбэка минимальная сумма проигрыша за 7 дней должна составлять не менее 1 EUR или эквивалента в другой валюте.\n
Кэшбэк рассчитывается по формуле:(сумма ставок - выигрыши - бонусные ставки) * n% кэшбэка = недельный кэшбэк.\n
n = % и определяется текущим уровнем в программе лояльности.\n
Доступ к еженедельному кэшбэку открывается на 2 уровне (Zircon).\n
Процент кэшбэка и требования к отыгрышу меняются с повышением уровня в VIP-клубе.\n
При достижении новых уровней игрок получает награды в форме фриспинов и денежных призов.\n
Доступ к ежедневному релоад-бонусу открывается на 7 уровне (Pyrite). Сумма бонуса рассчитывается на основе суммы ставок за прошлую неделю и не требует отыгрыша.\n
По вторникам доступна еженедельная акция Буст Вторника на бонус в размере 50% до 10 000 RUB.
По пятницам доступна еженедельная акция Пятничная Перезагрузка на бонус в размере 50% до 10000 RUB.
         `,
          payments: "Платежи",
          deposit_methods: "Способы депозита",
          withdraw_methods: "Способы вывода",
          deposit_history: 'История',
          no: "Нет",
          payments_description:
            "Команда Lotos Casino выводит средства в течение пяти минут после заполнения заявки. После этого перевод средств на ваш счет будет зависеть от платежной системы, на которую был запрошен вывод средств.",
          method: "Способ",
          type: "Тип",
          comission: "Комиссия",
          process_time: "Время обработки",
          transaction_limit: "Лимит на транзакцию",
          instantly: "Моментально",
          error_occurred: "Произошла ошибка!",
          invalid_password: "Неверный пароль!",
          need_login: "Чтобы выполнить это действие необходимо авторизоваться!",
          no_games_found: "Игр не найдено!",
          forgot_password: "Забыли пароль?",
          status: 'Статус',
          code_is_incorrect: "Код неверный!",
          code_was_sent: "Код был отправлен на ваш номер телефона!",
          code_was_sent_email: "Код был отправлен на ваш email-адрес!",
          insert_email_or_phone: "Введите E-mail или телефон",
          insert_your_password: "Введите пароль",
          confirmation_code: "Код подтверждения",
          find_a_game: "Найти игру",
          validation_error: "Проверьте правильность введенных данных!",
          email_to_recovery: "E-mail для восстановления",
          insert_email: "Введите E-mail",
          deposit_history: 'История',
          email_not_registered:
            "Пользователя с таким email-адресом не сущствует",
          password_changed: "Вы успешно сменили свой пароль!",
          incorrect_restore_link:
            "Ссылка для восстановления пароля недействительна!",
          password_recovery: "Восстановление пароля",
          session: "Сессия",
          history_is_clear: "История пуста!",
          wallet_stats: 'Статистика по кошельку',
          amount: "Сумма",
          use_promocode: "Использовать промокод",
          send_balance: "Отправить баланс",
          available_promotions: "Доступные акции",
          referral: 'Реферальная система',
          all_wallets: 'Все кошельки',
          this_week: 'На этой неделе',
          
          faq: 'Часто задаваемые вопросы',
          faq_desc_p1: "Если вы не нашли ответ на ваш вопрос, вы можете обратиться в поддержку",
bonuses_desc: 'Вам следует внимательно изучить настоящие Условия, поскольку они содержат важную информацию о ваших правах и обязанностях, связанных с использованием Веб-сайта, и образуют юридически обязывающее соглашение между вами - нашим клиентом ("Клиент") - и нами. Используя данный Веб-сайт и/или получая доступ к услугам, вы, независимо от того, являетесь ли вы гостем или зарегистрированным пользователем с учетной записью ("Учетная запись"), соглашаетесь соблюдать настоящие Условия, а также любые поправки, которые могут время от времени публиковаться. Если вы не принимаете настоящие Условия, вам следует воздержаться от доступа к Услуге и использования Веб-сайта.',
credentials: 'Реквизиты',
daily_limit: 'Лимит на вывод в день',
levels: 'Список уровней',
birthday_gifts: 'Подарки на день рождения',
fastWithdraw: 'Быстрый вывод (до 5 мин)',
link_copied: 'Ссылка скопирована',
fullDaySupport: 'Поддержка клиентов  24/7',
vipManager: 'VIP-Менеджер',
refill_wallet: "Пополнить кошелек",
wallet_history: 'История операций по кошельку',
ref_desc: 'Кэшбэк выплачивается еженедельно на счёт',
deposit_method: 'Метод пополнения',
withdraw_method: 'Метод вывода',

pay: 'Оплатить',
withdraw_from_wallet: 'Вывод средств с кошелька',
}
      },
    },
  });

export default i18n;
