import { useTranslation } from "react-i18next";
import ProfileMenu from "../../components/ProfileMenu";
import { useAtom } from "jotai";
import { idAtom } from "../../App";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import axios from "axios";

export default function Referral() {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState('сводка');
  const [id] = useAtom(idAtom);

  const [summaryLink, setSummaryLink] = useState("");
  const [summaryTgLink, setSummaryTgLink] = useState("");
  const [summaryRefStat, setSummaryRefStat] = useState([]);
  const [summaryData, setSummaryData] = useState({ wager: 0, income: 0 });
  const [summaryLastMonthIncome, setSummaryLastMonthIncome] = useState(0);

  const { start: currentWeekStart, end: currentWeekEnd } = getCurrentWeekRange();
  const [detailedStartDate, setDetailedStartDate] = useState(currentWeekStart);
  const [detailedEndDate, setDetailedEndDate] = useState(currentWeekEnd);
  const [detailedData, setDetailedData] = useState({ wager: 0, income: 0 });
  const [detailedUsers, setDetailedUsers] = useState([]);

  const [cashbackHistory, setCashbackHistory] = useState([]);

  function getCurrentWeekRange() {
    const now = new Date();
    const dayOfWeek = now.getDay();
    const diffToMonday = (dayOfWeek + 6) % 7;
    const monday = new Date(now);
    monday.setDate(now.getDate() - diffToMonday);
    monday.setHours(0, 0, 0, 0);
    const sunday = new Date(monday);
    sunday.setDate(monday.getDate() + 6);
    sunday.setHours(23, 59, 59, 999);
    const toLocalISO = (date) => {
      const tzOffset = date.getTimezoneOffset() * 60000;
      return new Date(date - tzOffset).toISOString().slice(0, 16);
    };
    return {
      start: toLocalISO(monday),
      end: toLocalISO(sunday)
    };
  }

  function getPreviousMonthRange() {
    const now = new Date();
    const start = new Date(now.getFullYear(), now.getMonth() - 1, 1, 0, 0, 0, 0);
    const end = new Date(now.getFullYear(), now.getMonth(), 0, 23, 59, 59, 999);
    const toLocalISO = (date) => {
      const tzOffset = date.getTimezoneOffset() * 60000;
      return new Date(date - tzOffset).toISOString().slice(0, 16);
    };
    return { start: toLocalISO(start), end: toLocalISO(end) };
  }

  useEffect(() => {
    const fetchSummaryData = async () => {
      const { start: lastMonthStart, end: lastMonthEnd } = getPreviousMonthRange();
      try {
        const response = await axios.get(process.env.REACT_APP_API + "/user/referrals", {
          params: { userId: id, startDate: lastMonthStart, endDate: lastMonthEnd },
          headers: { Authorization: "Bearer " + localStorage.getItem("accessToken") },
        });

        setSummaryTgLink(response.data.link.telegramLink);
        setSummaryLink(response.data.link.siteLink);
        const toFill = response.data.users.map(user => ({
          lvl: user.level,
          username: user.name || user.email || user.phone || user.telegram_username,
          cashback: user.cashback,
          lastEarned: user.lastTotalEarned,
          totalEarned: user.totalEarned,
        }));
        setSummaryRefStat(toFill);
        setSummaryData({
          wager: response.data.stats.lostAmount,
          income: response.data.stats.wonAmount,
        });
        setSummaryLastMonthIncome(response.data.stats.wonAmount);
      } catch (error) {
        console.error(error);
        toast.error(t('fetch_error'));
      }
    };

    if (activeTab === 'сводка' || activeTab === 'рефералы') {
      fetchSummaryData();
    }
  }, [id, activeTab, t]);

  useEffect(() => {
    const fetchDetailedData = async () => {
      const params = {
        userId: id,
        ...(detailedStartDate && { startDate: detailedStartDate }),
        ...(detailedEndDate && { endDate: detailedEndDate }),
      };
      try {
        const response = await axios.get(process.env.REACT_APP_API + "/user/referrals", {
          params,
          headers: { Authorization: "Bearer " + localStorage.getItem("accessToken") },
        });

        if (response.data.stats) {
          setDetailedData({
            wager: response.data.stats.lostAmount,
            income: response.data.stats.wonAmount,
          });
        }
        if (response.data.users) {
          const usersWithRevenue = response.data.users.filter(user => user.totalEarned > 0);
          setDetailedUsers(usersWithRevenue);
        }
      } catch (error) {
        console.error(error);
        toast.error(t('fetch_error'));
      }
    };

    if (activeTab === 'детализация') {
      fetchDetailedData();
    }
  }, [activeTab, detailedStartDate, detailedEndDate, id, t]);

  useEffect(() => {
    const fetchCashbackHistory = async () => {
      try {
        const response = await axios.get(process.env.REACT_APP_API + "/user/wallet-history", {
          headers: { Authorization: "Bearer " + localStorage.getItem("accessToken") },
        });
        const cashbackTransactions = response.data.transactions.filter(
          (tx) => tx.method === 'cashback'
        );
        const formattedHistory = cashbackTransactions.map((item) => ({
          date: item.timestamp,
          req: item.req || '',
          sum: item.amount,
          status:
            item.status === "success" ? 1 : item.status === "cancelled" ? -1 : 0,
          currency: 'RUB',
        }));
        setCashbackHistory(formattedHistory);
      } catch (err) {
        console.error(err);
        toast.error(t('fetch_error'));
      }
    };

    if (activeTab === 'история') {
      fetchCashbackHistory();
    }
  }, [activeTab, t]);

  const copyTextToClipboard = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      toast.success(t('link_copied'));
    } catch (error) {
      toast.error(t('copy_error'));
    }
  };

  const renderSummary = () => (
    <div>
      <p>{t('referral_desc')}</p>
      <p style={{ margin: '20px 0' }}>Статистика за прошлый месяц</p>
      <div className="refStat">
        <div>
          <div>
            <svg
              width="16px"
              height="16px"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.052 1.25H11.948C11.0495 1.24997 10.3003 1.24995 9.70552 1.32991C9.07773 1.41432 8.51093 1.59999 8.05546 2.05546C7.59999 2.51093 7.41432 3.07773 7.32991 3.70552C7.27259 4.13189 7.25637 5.15147 7.25179 6.02566C5.22954 6.09171 4.01536 6.32778 3.17157 7.17157C2 8.34315 2 10.2288 2 14C2 17.7712 2 19.6569 3.17157 20.8284C4.34314 22 6.22876 22 9.99998 22H14C17.7712 22 19.6569 22 20.8284 20.8284C22 19.6569 22 17.7712 22 14C22 10.2288 22 8.34315 20.8284 7.17157C19.9846 6.32778 18.7705 6.09171 16.7482 6.02566C16.7436 5.15147 16.7274 4.13189 16.6701 3.70552C16.5857 3.07773 16.4 2.51093 15.9445 2.05546C15.4891 1.59999 14.9223 1.41432 14.2945 1.32991C13.6997 1.24995 12.9505 1.24997 12.052 1.25ZM15.2479 6.00188C15.2434 5.15523 15.229 4.24407 15.1835 3.9054C15.1214 3.44393 15.0142 3.24644 14.8839 3.11612C14.7536 2.9858 14.5561 2.87858 14.0946 2.81654C13.6116 2.7516 12.964 2.75 12 2.75C11.036 2.75 10.3884 2.7516 9.90539 2.81654C9.44393 2.87858 9.24644 2.9858 9.11612 3.11612C8.9858 3.24644 8.87858 3.44393 8.81654 3.9054C8.771 4.24407 8.75661 5.15523 8.75208 6.00188C9.1435 6 9.55885 6 10 6H14C14.4412 6 14.8565 6 15.2479 6.00188ZM12 9.25C12.4142 9.25 12.75 9.58579 12.75 10V10.0102C13.8388 10.2845 14.75 11.143 14.75 12.3333C14.75 12.7475 14.4142 13.0833 14 13.0833C13.5858 13.0833 13.25 12.7475 13.25 12.3333C13.25 11.9493 12.8242 11.4167 12 11.4167C11.1758 11.4167 10.75 11.9493 10.75 12.3333C10.75 12.7174 11.1758 13.25 12 13.25C13.3849 13.25 14.75 14.2098 14.75 15.6667C14.75 16.857 13.8388 17.7155 12.75 17.9898V18C12.75 18.4142 12.4142 18.75 12 18.75C11.5858 18.75 11.25 18.4142 11.25 18V17.9898C10.1612 17.7155 9.25 16.857 9.25 15.6667C9.25 15.2525 9.58579 14.9167 10 14.9167C10.4142 14.9167 10.75 15.2525 10.75 15.6667C10.75 16.0507 11.1758 16.5833 12 16.5833C12.8242 16.5833 13.25 16.0507 13.25 15.6667C13.25 15.2826 12.8242 14.75 12 14.75C10.6151 14.75 9.25 13.7903 9.25 12.3333C9.25 11.143 10.1612 10.2845 11.25 10.0102V10C11.25 9.58579 11.5858 9.25 12 9.25Z"
                fill="white"
              />
            </svg>
            <span>Ставки рефералов</span>
          </div>
          <p>{summaryData.wager} RUB</p>
        </div>
        <div>
          <div>
            <svg
              width="16px"
              height="16px"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.052 1.25H11.948C11.0495 1.24997 10.3003 1.24995 9.70552 1.32991C9.07773 1.41432 8.51093 1.59999 8.05546 2.05546C7.59999 2.51093 7.41432 3.07773 7.32991 3.70552C7.27259 4.13189 7.25637 5.15147 7.25179 6.02566C5.22954 6.09171 4.01536 6.32778 3.17157 7.17157C2 8.34315 2 10.2288 2 14C2 17.7712 2 19.6569 3.17157 20.8284C4.34314 22 6.22876 22 9.99998 22H14C17.7712 22 19.6569 22 20.8284 20.8284C22 19.6569 22 17.7712 22 14C22 10.2288 22 8.34315 20.8284 7.17157C19.9846 6.32778 18.7705 6.09171 16.7482 6.02566C16.7436 5.15147 16.7274 4.13189 16.6701 3.70552C16.5857 3.07773 16.4 2.51093 15.9445 2.05546C15.4891 1.59999 14.9223 1.41432 14.2945 1.32991C13.6997 1.24995 12.9505 1.24997 12.052 1.25ZM15.2479 6.00188C15.2434 5.15523 15.229 4.24407 15.1835 3.9054C15.1214 3.44393 15.0142 3.24644 14.8839 3.11612C14.7536 2.9858 14.5561 2.87858 14.0946 2.81654C13.6116 2.7516 12.964 2.75 12 2.75C11.036 2.75 10.3884 2.7516 9.90539 2.81654C9.44393 2.87858 9.24644 2.9858 9.11612 3.11612C8.9858 3.24644 8.87858 3.44393 8.81654 3.9054C8.771 4.24407 8.75661 5.15523 8.75208 6.00188C9.1435 6 9.55885 6 10 6H14C14.4412 6 14.8565 6 15.2479 6.00188ZM12 9.25C12.4142 9.25 12.75 9.58579 12.75 10V10.0102C13.8388 10.2845 14.75 11.143 14.75 12.3333C14.75 12.7475 14.4142 13.0833 14 13.0833C13.5858 13.0833 13.25 12.7475 13.25 12.3333C13.25 11.9493 12.8242 11.4167 12 11.4167C11.1758 11.4167 10.75 11.9493 10.75 12.3333C10.75 12.7174 11.1758 13.25 12 13.25C13.3849 13.25 14.75 14.2098 14.75 15.6667C14.75 16.857 13.8388 17.7155 12.75 17.9898V18C12.75 18.4142 12.4142 18.75 12 18.75C11.5858 18.75 11.25 18.4142 11.25 18V17.9898C10.1612 17.7155 9.25 16.857 9.25 15.6667C9.25 15.2525 9.58579 14.9167 10 14.9167C10.4142 14.9167 10.75 15.2525 10.75 15.6667C10.75 16.0507 11.1758 16.5833 12 16.5833C12.8242 16.5833 13.25 16.0507 13.25 15.6667C13.25 15.2826 12.8242 14.75 12 14.75C10.6151 14.75 9.25 13.7903 9.25 12.3333C9.25 11.143 10.1612 10.2845 11.25 10.0102V10C11.25 9.58579 11.5858 9.25 12 9.25Z"
                fill="white"
              />
            </svg>
            <span>Ваша комиссия</span>
          </div>
          <p>{summaryData.income} RUB</p>
        </div>
      </div>
      <div className="Profile-Block__fields ref-fields">
        <div>
          <nav>
            <div className="container-ref-img">
              <img src="../../images/ref.png" alt="" />
            </div>
            <p>Партнерская ссылка для игры на сайте </p>
            <div className="copy-input">
              <input type="text" disabled value={summaryLink} />
              <svg
                onClick={() => copyTextToClipboard(summaryLink)}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
              >
                <path d="M21 10v10a1 1 0 0 1-1 1H10a1 1 0 0 1-1-1V10a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1zM6 14H5V5h9v1a1 1 0 0 0 2 0V4a1 1 0 0 0-1-1H4a1 1 0 0 0-1 1v11a1 1 0 0 0 1 1h2a1 1 0 0 0 0-2z" />
              </svg>
            </div>
            <p>Партнерская ссылка для игры в телеграм </p>
            <div className="copy-input">
              <input type="text" disabled value={summaryTgLink} />
              <svg
                onClick={() => copyTextToClipboard(summaryTgLink)}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
              >
                <path d="M21 10v10a1 1 0 0 1-1 1H10a1 1 0 0 1-1-1V10a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1zM6 14H5V5h9v1a1 1 0 0 0 2 0V4a1 1 0 0 0-1-1H4a1 1 0 0 0-1 1v11a1 1 0 0 0 1 1h2a1 1 0 0 0 0-2z" />
              </svg>
            </div>
          </nav>
        </div>
      </div>
    </div>
  );

  const renderDetailed = () => (
    <div>
      <h2 className="det">Детализация</h2>
      <div className="date-filters">
        <input 
          type="datetime-local" 
          value={detailedStartDate} 
          onChange={(e) => setDetailedStartDate(e.target.value)} 
          placeholder={t('start_date')}
        />
        <input 
          type="datetime-local" 
          value={detailedEndDate} 
          onChange={(e) => setDetailedEndDate(e.target.value)} 
          placeholder={t('end_date')}
        />
      </div>
      <div className="refStat">
        <div>
          <div>
            <svg
              width="16px"
              height="16px"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.052 1.25H11.948C11.0495 1.24997 10.3003 1.24995 9.70552 1.32991C9.07773 1.41432 8.51093 1.59999 8.05546 2.05546C7.59999 2.51093 7.41432 3.07773 7.32991 3.70552C7.27259 4.13189 7.25637 5.15147 7.25179 6.02566C5.22954 6.09171 4.01536 6.32778 3.17157 7.17157C2 8.34315 2 10.2288 2 14C2 17.7712 2 19.6569 3.17157 20.8284C4.34314 22 6.22876 22 9.99998 22H14C17.7712 22 19.6569 22 20.8284 20.8284C22 19.6569 22 17.7712 22 14C22 10.2288 22 8.34315 20.8284 7.17157C19.9846 6.32778 18.7705 6.09171 16.7482 6.02566C16.7436 5.15147 16.7274 4.13189 16.6701 3.70552C16.5857 3.07773 16.4 2.51093 15.9445 2.05546C15.4891 1.59999 14.9223 1.41432 14.2945 1.32991C13.6997 1.24995 12.9505 1.24997 12.052 1.25ZM15.2479 6.00188C15.2434 5.15523 15.229 4.24407 15.1835 3.9054C15.1214 3.44393 15.0142 3.24644 14.8839 3.11612C14.7536 2.9858 14.5561 2.87858 14.0946 2.81654C13.6116 2.7516 12.964 2.75 12 2.75C11.036 2.75 10.3884 2.7516 9.90539 2.81654C9.44393 2.87858 9.24644 2.9858 9.11612 3.11612C8.9858 3.24644 8.87858 3.44393 8.81654 3.9054C8.771 4.24407 8.75661 5.15523 8.75208 6.00188C9.1435 6 9.55885 6 10 6H14C14.4412 6 14.8565 6 15.2479 6.00188ZM12 9.25C12.4142 9.25 12.75 9.58579 12.75 10V10.0102C13.8388 10.2845 14.75 11.143 14.75 12.3333C14.75 12.7475 14.4142 13.0833 14 13.0833C13.5858 13.0833 13.25 12.7475 13.25 12.3333C13.25 11.9493 12.8242 11.4167 12 11.4167C11.1758 11.4167 10.75 11.9493 10.75 12.3333C10.75 12.7174 11.1758 13.25 12 13.25C13.3849 13.25 14.75 14.2098 14.75 15.6667C14.75 16.857 13.8388 17.7155 12.75 17.9898V18C12.75 18.4142 12.4142 18.75 12 18.75C11.5858 18.75 11.25 18.4142 11.25 18V17.9898C10.1612 17.7155 9.25 16.857 9.25 15.6667C9.25 15.2525 9.58579 14.9167 10 14.9167C10.4142 14.9167 10.75 15.2525 10.75 15.6667C10.75 16.0507 11.1758 16.5833 12 16.5833C12.8242 16.5833 13.25 16.0507 13.25 15.6667C13.25 15.2826 12.8242 14.75 12 14.75C10.6151 14.75 9.25 13.7903 9.25 12.3333C9.25 11.143 10.1612 10.2845 11.25 10.0102V10C11.25 9.58579 11.5858 9.25 12 9.25Z"
                fill="white"
              />
            </svg>
            <span>{t('lost_by_users')}</span>
          </div>
          <p>{detailedData.wager} RUB</p>
        </div>
        <div>
          <div>
            <svg
              width="16px"
              height="16px"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.052 1.25H11.948C11.0495 1.24997 10.3003 1.24995 9.70552 1.32991C9.07773 1.41432 8.51093 1.59999 8.05546 2.05546C7.59999 2.51093 7.41432 3.07773 7.32991 3.70552C7.27259 4.13189 7.25637 5.15147 7.25179 6.02566C5.22954 6.09171 4.01536 6.32778 3.17157 7.17157C2 8.34315 2 10.2288 2 14C2 17.7712 2 19.6569 3.17157 20.8284C4.34314 22 6.22876 22 9.99998 22H14C17.7712 22 19.6569 22 20.8284 20.8284C22 19.6569 22 17.7712 22 14C22 10.2288 22 8.34315 20.8284 7.17157C19.9846 6.32778 18.7705 6.09171 16.7482 6.02566C16.7436 5.15147 16.7274 4.13189 16.6701 3.70552C16.5857 3.07773 16.4 2.51093 15.9445 2.05546C15.4891 1.59999 14.9223 1.41432 14.2945 1.32991C13.6997 1.24995 12.9505 1.24997 12.052 1.25ZM15.2479 6.00188C15.2434 5.15523 15.229 4.24407 15.1835 3.9054C15.1214 3.44393 15.0142 3.24644 14.8839 3.11612C14.7536 2.9858 14.5561 2.87858 14.0946 2.81654C13.6116 2.7516 12.964 2.75 12 2.75C11.036 2.75 10.3884 2.7516 9.90539 2.81654C9.44393 2.87858 9.24644 2.9858 9.11612 3.11612C8.9858 3.24644 8.87858 3.44393 8.81654 3.9054C8.771 4.24407 8.75661 5.15523 8.75208 6.00188C9.1435 6 9.55885 6 10 6H14C14.4412 6 14.8565 6 15.2479 6.00188ZM12 9.25C12.4142 9.25 12.75 9.58579 12.75 10V10.0102C13.8388 10.2845 14.75 11.143 14.75 12.3333C14.75 12.7475 14.4142 13.0833 14 13.0833C13.5858 13.0833 13.25 12.7475 13.25 12.3333C13.25 11.9493 12.8242 11.4167 12 11.4167C11.1758 11.4167 10.75 11.9493 10.75 12.3333C10.75 12.7174 11.1758 13.25 12 13.25C13.3849 13.25 14.75 14.2098 14.75 15.6667C14.75 16.857 13.8388 17.7155 12.75 17.9898V18C12.75 18.4142 12.4142 18.75 12 18.75C11.5858 18.75 11.25 18.4142 11.25 18V17.9898C10.1612 17.7155 9.25 16.857 9.25 15.6667C9.25 15.2525 9.58579 14.9167 10 14.9167C10.4142 14.9167 10.75 15.2525 10.75 15.6667C10.75 16.0507 11.1758 16.5833 12 16.5833C12.8242 16.5833 13.25 16.0507 13.25 15.6667C13.25 15.2826 12.8242 14.75 12 14.75C10.6151 14.75 9.25 13.7903 9.25 12.3333C9.25 11.143 10.1612 10.2845 11.25 10.0102V10C11.25 9.58579 11.5858 9.25 12 9.25Z"
                fill="white"
              />
            </svg>
            <span>{t('earned_from_users')}</span>
          </div>
          <p>{detailedData.income} RUB</p>
        </div>
      </div>
      <div className="referrals-wrap">
        <div className="referrals">
          {detailedUsers.length === 0 ? (
            <p>{t('no_users_found')}</p>
          ) : (
            detailedUsers.map((user, index) => (
              <div key={index}>
                <p>
                  {user.name || user.email || user.phone || user.telegram_username || t('unknown_user')} { user.level != "собственный" ? `(${user.level} ур.)` : "10%"}
                </p>
                <span>{user.cashback.toFixed(2)} RUB</span>
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  );

  const renderReferrals = () => (
    <div>
      <div className="Profile-Block__fields ref-fields">
        <div>
          <nav>
            <p>{t('top_users')} (lvl 1)</p>
            <div className="referrals">
              {summaryRefStat == null || summaryRefStat.filter((e) => e.lvl === 1).length === 0 ? (
                <p>{t('users_not_found')}</p>
              ) : (
                summaryRefStat.filter((e) => e.lvl === 1).map((elem, index) => (
                  <div key={index}>
                    <p>{elem.username}</p>
                    <p>Ваша комиссия 10%</p>
                  </div>
                ))
              )}
            </div>
            <p>{t('top_users')} (lvl 2)</p>
            <div className="referrals">
              {summaryRefStat == null || summaryRefStat.filter((e) => e.lvl === 2).length === 0 ? (
                <p>{t('users_not_found')}</p>
              ) : (
                summaryRefStat.filter((e) => e.lvl === 2).map((elem, index) => (
                  <div key={index}>
                    <p>{elem.username}</p>
                    <p>Ваша комиссия 5%</p>
                  </div>
                ))
              )}
            </div>
            <p>{t('top_users')} (lvl 3)</p>
            <div className="referrals">
              {summaryRefStat == null || summaryRefStat.filter((e) => e.lvl === 3).length === 0 ? (
                <p>{t('users_not_found')}</p>
              ) : (
                summaryRefStat.filter((e) => e.lvl === 3).map((elem, index) => (
                  <div key={index}>
                    <p>{elem.username}</p>
                    <p>Ваша комиссия 3%</p>
                  </div>
                ))
              )}
            </div>
          </nav>
        </div>
      </div>
    </div>
  );

  const renderHistory = () => (
    <div>
      <h2 style={{ marginBottom: '20px' }}>История</h2>
      <div className="Wallet-History">
        <section>
          <p>{t('date')}</p>
          <p>{t('credentials')}</p>
          <p>{t('amount')}</p>
          <p>{t('status')}</p>
        </section>
        <div>
          {cashbackHistory.length === 0 ? (
            <p style={{ opacity: '0.7', textAlign: 'center' }}>
              Операций не найдено
            </p>
          ) : (
            cashbackHistory
              .sort((a, b) => Number(new Date(b.date)) - Number(new Date(a.date)))
              .map((item, index) => (
                <div key={index}>
                  <nav>
                    <p>{new Date(item.date).toLocaleString('en-GB')}</p>
                    <p>{item.req}</p>
                    <p>
                      {item.sum.toLocaleString('en-US')} {item.currency}
                    </p>
                    <p className={
                      item.status === 1
                        ? "success"
                        : item.status === 0
                          ? "waiting"
                          : "error"
                    }>
                      {item.status === 1
                        ? t('success')
                        : item.status === 0
                          ? t('waiting')
                          : t('cancelled')}
                    </p>
                  </nav>
                </div>
              ))
          )}
        </div>
      </div>
    </div>
  );

  return (
    <div className="Profile-Block">
      <ProfileMenu url="/profile/referral" />
      <div className="Profile-Block__container">
        <h1>{t('referral')}</h1>
        <div className="tabs">
          <button className="referal-button" onClick={() => setActiveTab('сводка')}>
            {t('сводка')}
          </button>
          <button className="referal-button" onClick={() => setActiveTab('детализация')}>
            {t('детализация')}
          </button>
          <button className="referal-button" onClick={() => setActiveTab('рефералы')}>
            {t('рефералы')}
          </button>
          <button className="referal-button" onClick={() => setActiveTab('история')}>
            {t('история')}
          </button>
        </div>

        {activeTab === 'сводка' && renderSummary()}
        {activeTab === 'детализация' && renderDetailed()}
        {activeTab === 'рефералы' && renderReferrals()}
        {activeTab === 'история' && renderHistory()}
      </div>
    </div>
  );
}
